import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const homeRoute = "dashboard"
const homeRoute = 'towers'

let router = new VueRouter({
  routes: [{
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    redirect: {
      name: 'towers'
    }
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
      layout: 'no-navbar'
    }
  }, {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/tower',
    name: 'towers',
    component: () => import('../views/Towers.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/tower/:id',
    name: 'tower.details',
    component: () => import('../views/TowerDetails.vue'),
    children: [{
      name: "tower.details.overview",
      path: 'overview',
      component: () => import('../components/TowerDetails/Overview.vue')
    }, {
      name: "tower.details.assets",
      path: 'assets',
      component: () => import('../components/TowerDetails/Assets.vue')
    }, {
      name: "tower.details.documents",
      path: 'documents',
      component: () => import('../components/TowerDetails/Documents.vue')
    }, {
      name: "tower.details.interactiveTour",
      path: 'interactive-tour',
      component: () => import('../components/TowerDetails/InteractiveTour.vue')
    }, {
      name: "tower.details.3dVisualisation",
      path: '3d-visualisation',
      component: () => import('../components/TowerDetails/3DVisualisation.vue')
    }, {
      name: "tower.details.requestInstallation",
      path: 'request-installation',
      component: () => import('../components/TowerDetails/RequestInstallation.vue')
    }, {
      name: "tower.details.azimuth",
      path: 'azimuth',
      component: () => import('../components/TowerDetails/Azimuth.vue')
    }],
    meta: {
      requiresAuth: true
    },
  }, {
    path: '/request',
    name: 'request',
    component: () => import('../views/Request.vue'),
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/work-order',
    name: 'workOrder',
    component: () => import('../views/WorkOrder.vue'),
    meta: {
      requiresAuth: true
    }
  }]
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token == null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      // let user = JSON.parse(localStorage.getItem('user'))

      // if (to.matched.some(record => record.meta.isAdmin)) {
      //   if (user.isAdmin == 1) {
      //     next()
      //   } else {
      //     next({
      //       name: homeRoute
      //     })
      //   }
      // } else {
      next()
      // }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token == null) {
      next()
    } else {
      next({
        name: homeRoute
      })
    }
  } else {
    next()
  }
})

export default router