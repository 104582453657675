<template>
  <div class="container-fluid p-0">
    <nav class="navbar navbar-expand-lg navbar-light bg-white p-0">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/terradrone_g.png" />
      </a>
      <button
        class="navbar-toggler border-0 mr-2"
        type="button"
        @click="toggleMobileNavbar()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse h-100">
        <ul class="navbar-nav mr-auto h-100">
          <!-- <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'dashboard' }">
              <img src="@/assets/icons/dashboard.png" />
              <span>Dashboard</span>
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="{ 'router-link-active': $route.name == 'towerDetails' }"
              :to="{ name: 'towers' }"
            >
              <img src="@/assets/icons/cell-tower.png" />
              <span>Towers</span>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'request' }">
              <img src="@/assets/icons/request.jpg" class="rounded-circle" />
              <span>Request</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'workOrder' }">
              <img
                src="@/assets/icons/construction-worker.png"
                class="rounded-circle"
              />
              <span>Work Order</span>
            </router-link>
          </li> -->
        </ul>
        <div
          id="navbarUserDropdownContainer"
          class="position-relative"
          @mouseover="toggleUserDropdown('show')"
          @mouseleave="toggleUserDropdown('hide')"
        >
          <button
            class="btn btn-link text-secondary px-0 dropdown-toggle shadow-none"
            id="navbarUserDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="@/assets/icons/user-default.jpg" />
            <div class="text-left px-3">
              <span class="small text-muted">Welcome</span><br />
              <span class="text-dark font-weight-bold">{{ username }}</span>
            </div>
            <i class="fal fa-chevron-down"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right text-center w-100"
            aria-labelledby="navbarDropdown"
          >
            <a class="dropdown-item" href="#" @click.prevent="logout()">
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
    <div id="navbarMobileContainer">
      <div id="navbarMobileContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'dashboard' }">
              <img src="@/assets/icons/dashboard.png" />
              <span>Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="{ 'router-link-active': $route.name == 'towerDetails' }"
              :to="{ name: 'towers' }"
            >
              <img src="@/assets/icons/cell-tower.png" />
              <span>Towers</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'request' }">
              <img src="@/assets/icons/request.jpg" class="rounded-circle" />
              <span>Request</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'workOrder' }">
              <img
                src="@/assets/icons/construction-worker.png"
                class="rounded-circle"
              />
              <span>Work Order</span>
            </router-link>
          </li>
          <li class="nav-item pt-1 bg-white"></li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logout()">
              <img
                src="@/assets/icons/user-default.jpg"
                class="rounded-circle shadow-sm"
                width="45"
                height="45"
              />
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="position-relative" style="z-index: 10">
      <slot class="text" />
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import $ from "jquery";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    spinner: Spinner,
  },
  data() {
    return {
      username: null,
      isLoading: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.toggleMobileNavbar("hide");
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleUserDropdown(type) {
      $("#navbarUserDropdown").dropdown(type);
    },
    toggleMobileNavbar(type) {
      if (type == "hide") {
        $("#navbarMobileContent").slideUp("fast");
      } else {
        $("#navbarMobileContent").slideToggle("fast");
      }
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.push({
        name: "login",
      });
    },
  },
  mounted() {
    this.username = "Demo User";
  },
};
</script>
