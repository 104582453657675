<template>
  <div
    class="modal fade"
    id="galleryModal"
    tabindex="-1"
    aria-labelledby="galleryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <div id="imageGallery" class="h-100 w-100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import API from "@/api";
import OSD from "openseadragon";

export default {
  name: "galleryModal",
  props: ["type", "id"],
  data() {
    return {
      osd: null,
    };
  },
  methods: {
    loadOSD(url) {
      this.osd.open({
        type: "image",
        url: url,
      });
    },
    unloadOSD() {
      this.osd.close();
    },
  },
  mounted() {
    let vm = this;

    this.osd = OSD({
      id: "imageGallery",
      prefixUrl: "/osd/",
      crossOriginPolicy: "Anonymous",
      visibilityRatio: 	1,
      maxZoomLevel: 30
    });

    $("#galleryModal").on("shown.bs.modal", async function (e) {
      let media = (await API.get(`${vm.type}/${vm.id}`)).data;

      let url = null;

      if (vm.type == "assets") {
        url = media.image.url;
      } else if (vm.type == "issues") {
        url = media.image.url;
      }

      vm.loadOSD(url);
    });

    $("#galleryModal").on("hidden.bs.modal", function (e) {
      vm.unloadOSD();
      vm.$emit("close");
    });
  },
};
</script>