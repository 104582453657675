<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <gallery-modal
      :type="$route.query.media_type"
      :id="$route.query.media_id"
      @close="closeGallery"
    />
  </div>
</template>

<script>
import $ from "jquery";
import GalleryModal from "@/components/modals/GalleryModal";

export default {
  name: "app",
  components: {
    "gallery-modal": GalleryModal,
  },
  data() {
    return {
      defaultLayout: "default",
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (
          typeof this.$route.query.media_type != "undefined" &&
          typeof this.$route.query.media_id != "undefined"
        ) {
          $("#galleryModal").modal("show");
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    layout() {
      let layout = `${this.$route.meta.layout || this.defaultLayout}-layout`;
      return layout;
    },
  },
  methods: {
    closeGallery() {
      $("#galleryModal").modal("hide");
      this.$router.replace({
        query: {},
      });
    },
  },
};
</script>
