<template>
  <div class="loading-spinner" v-if="show">
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "spinner",
  props: ["show"],
  watch: {
    show: {
      handler() {
        if (this.show) {
          $("body").addClass("overflow-hidden");
        } else {
          $("body").removeClass("overflow-hidden");
        }
      },
      immediate: true,
    },
  },
};
</script>