import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import '@/assets/fonts/fontawesome-pro-5.10.1/js/all'
import '@/assets/css/main.scss'
import Default from '@/layouts/Default'
import NoNavbar from '@/layouts/NoNavbar'

Vue.component('default-layout', Default)
Vue.component('no-navbar-layout', NoNavbar)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')